import { States } from '@/Redux/Slices/HeaderSlice/interfaces';

export const initialState: States = {
	CurrentFontSize: 1,
	theme: 'WHITE',
	currentPagePC: NaN,
	currentTagsId: 852,
	isStory: false,
	volume: 0.2,
};
