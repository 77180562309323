'use client';

import style from './quote.module.sass';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { SliderMain } from '@/Features/Slider/Slider';
import Link from 'next/link';
import { OpenTeaser } from '@/Hooks/useTiser';

export default function Quote() {
	const [pages, setPages] = useState([]);

	useEffect(() => {
		if (typeof JsAPI !== 'undefined') {
			JsAPI.Dao.getNews(
				{
					block_id: 92472,
					count: 5,
					fields: 65535
				},
				function (payload: any): void {
					setPages(payload);
				}
			);
		}
	}, []);
	return (
		<SliderMain pages={pages} setPages={setPages}>
			{pages.length == 0 && (
				<section className={style.Wrapper}>
					<div className={style.VideoTitleLoading}>
						<svg
							width='13'
							height='13'
							viewBox='0 0 13 13'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M12.8818 3.14536H13V0.486271H12.8227C12.0545 0.604452 11.3455 0.781714 10.6955 1.19535C10.0455 1.5499 9.51364 1.96354 9.04091 2.55445C8.56818 3.08627 8.21364 3.73626 7.97727 4.44535C7.74091 5.09535 7.62273 5.80444 7.62273 6.51353C7.62273 6.57262 7.62273 6.63173 7.62273 6.74991V12.3635H13V6.57263H10.6364C10.6364 5.74535 10.8136 5.09536 11.1091 4.56354C11.4045 3.91354 11.9955 3.44082 12.8818 3.14536ZM3.07273 1.19535C2.42273 1.5499 1.89091 1.96354 1.41818 2.55445C0.945455 3.08627 0.590908 3.73626 0.354545 4.44535C0.118181 5.09535 0 5.80444 0 6.51353C0 6.57262 0 6.63172 0 6.69082V12.3045H5.37727V6.51353H3.01364C3.01364 5.68626 3.19091 5.03626 3.48636 4.50444C3.78182 3.91354 4.37273 3.49991 5.25909 3.20446H5.37727V0.545365H5.2C4.43182 0.604456 3.72273 0.840805 3.07273 1.19535Z'
								fill='#45AD4E'
							/>
						</svg>
						<h4>Цитата дня</h4>
					</div>
					<div className={style.videoPreview}>
						<div className={style.quoteLazy}></div>
						<svg
							width='28'
							height='24'
							viewBox='0 0 28 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							className={style.quoteSvg}
						>
							<g filter='url(#filter0_d_32_49)'>
								<path
									d='M21.0005 0.999969L1.00049 0.999969L11.0005 17L21.0005 0.999969Z'
									fill='white'
								/>
							</g>
							<defs>
								<filter
									id='filter0_d_32_49'
									x='0.000488281'
									y='-3.05176e-05'
									width='28'
									height='24'
									filterUnits='userSpaceOnUse'
									colorInterpolationFilters='sRGB'
								>
									<feFlood floodOpacity='0' result='BackgroundImageFix' />
									<feColorMatrix
										in='SourceAlpha'
										type='matrix'
										values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
										result='hardAlpha'
									/>
									<feOffset dx='3' dy='3' />
									<feGaussianBlur stdDeviation='2' />
									<feComposite in2='hardAlpha' operator='out' />
									<feColorMatrix
										type='matrix'
										values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
									/>
									<feBlend
										mode='normal'
										in2='BackgroundImageFix'
										result='effect1_dropShadow_32_49'
									/>
									<feBlend
										mode='normal'
										in='SourceGraphic'
										in2='effect1_dropShadow_32_49'
										result='shape'
									/>
								</filter>
							</defs>
						</svg>
					</div>
					<div className={style.quoteAuthor}>
						<div className={style.authorNameDiv}>
							<span className={style.authorNameDiv}></span>
							<span className={style.authorSite}></span>
						</div>
					</div>
				</section>
			)}
			{pages.map((el: any) => {
				return (
					<section className={style.wrapperOfwrapper} key={el.id}>
						<section className={style.Wrapper}>
							<a
								href={`${el.url}`}
								style={{ textDecoration: 'none' }}
								target={window.ReactNativeWebView ? '_self' : '_blank'}
								onClick={e => {
									OpenTeaser(el);
									if (window.ReactNativeWebView) {
										e.preventDefault();
									}
								}}
							>
								<div className={style.VideoTitle}>
									<svg
										width='13'
										height='13'
										viewBox='0 0 13 13'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M12.8818 3.14536H13V0.486271H12.8227C12.0545 0.604452 11.3455 0.781714 10.6955 1.19535C10.0455 1.5499 9.51364 1.96354 9.04091 2.55445C8.56818 3.08627 8.21364 3.73626 7.97727 4.44535C7.74091 5.09535 7.62273 5.80444 7.62273 6.51353C7.62273 6.57262 7.62273 6.63173 7.62273 6.74991V12.3635H13V6.57263H10.6364C10.6364 5.74535 10.8136 5.09536 11.1091 4.56354C11.4045 3.91354 11.9955 3.44082 12.8818 3.14536ZM3.07273 1.19535C2.42273 1.5499 1.89091 1.96354 1.41818 2.55445C0.945455 3.08627 0.590908 3.73626 0.354545 4.44535C0.118181 5.09535 0 5.80444 0 6.51353C0 6.57262 0 6.63172 0 6.69082V12.3045H5.37727V6.51353H3.01364C3.01364 5.68626 3.19091 5.03626 3.48636 4.50444C3.78182 3.91354 4.37273 3.49991 5.25909 3.20446H5.37727V0.545365H5.2C4.43182 0.604456 3.72273 0.840805 3.07273 1.19535Z'
											fill='#45AD4E'
										/>
									</svg>
									<h4>Цитата дня</h4>
								</div>
								<div className={style.videoPreview}>
									<div className={style.quote}>{el.text}</div>
									<svg
										width='28'
										height='24'
										viewBox='0 0 28 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
										className={style.quoteSvg}
									>
										<g filter='url(#filter0_d_32_49)'>
											<path
												d='M21.0005 0.999969L1.00049 0.999969L11.0005 17L21.0005 0.999969Z'
												fill='white'
											/>
										</g>
										<defs>
											<filter
												id='filter0_d_32_49'
												x='0.000488281'
												y='-3.05176e-05'
												width='28'
												height='24'
												filterUnits='userSpaceOnUse'
												colorInterpolationFilters='sRGB'
											>
												<feFlood floodOpacity='0' result='BackgroundImageFix' />
												<feColorMatrix
													in='SourceAlpha'
													type='matrix'
													values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
													result='hardAlpha'
												/>
												<feOffset dx='3' dy='3' />
												<feGaussianBlur stdDeviation='2' />
												<feComposite in2='hardAlpha' operator='out' />
												<feColorMatrix
													type='matrix'
													values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
												/>
												<feBlend
													mode='normal'
													in2='BackgroundImageFix'
													result='effect1_dropShadow_32_49'
												/>
												<feBlend
													mode='normal'
													in='SourceGraphic'
													in2='effect1_dropShadow_32_49'
													result='shape'
												/>
											</filter>
										</defs>
									</svg>
								</div>
								<div className={style.quoteAuthor}>
									<Image
										width={32}
										height={35}
										src={`https:${el.image}`}
										alt='quoteAuthor'
										style={{ borderRadius: '100px' }}
									/>
									<div className={style.authorNameDiv}>
										<span className={style.authorNameDiv}>{el.title}</span>
										<span className={style.authorSite}>{el.domain}</span>
									</div>
								</div>
							</a>
						</section>
					</section>
				);
			})}
		</SliderMain>
	);
}
